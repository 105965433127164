/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const initialState = {
    modal: {
        open: false,
        title: '',
        content: {},
        customClass: '',
        layout: '',
        config: '',
    },
    giftList: {
        pagination: { count: 0, pageSize: 5, currentPage: 1 },
    },
    common: {
        addressVerification: {},
    },
    modalHistory: [],
    recipientOpen: 0,
    pageData: {},
    isFetchingModal: true,
    isReadyState: true,
    toggleState: false,
    recipients: [],
    singleAddressGet: {},
    addNewConfig: '',
    addForm: '',
    openDialog: false,
    addFormLable: '',
    ui: {
        brandId: 'HD',
    },
    app: {
        config: {
            byDomain: {},
        },
        shell: {
            loaded: true,
            type: 'home',
        },
        brand: {
            byId: {
                HD: {
                    id: '1019',
                },
            },
            id: '1019',
            code: 'HD',
            fsOrgId: 'SCECX',
            domain: 'harryanddavid',
            storeId: 21051,
            catalogId: 16802,
            description: 'Harry & David',
            palette: {
                colorPrimary: '#1f1f1b',
                colorPrimaryContrast: '#fff',
                colorSecondary: '#600',
                colorSecondaryContrast: '#fff',
                colorTertiary: '#1f1f1b',
                colorTertiaryContrast: '#fff',
                colorQuaternary: '#1f1f1b',
                colorQuaternaryContrast: '#fff',
                colorQuinary: '#1f1f1b',
                colorQuinaryContrast: '#fff',
                colorNeutral: '#1f1f1b',
                colorNeutral10: '#fafafa',
                colorNeutral20: '#f5f5f5',
                colorNeutral30: '#eee',
                colorNeutral40: '#e0e0e0',
                colorNeutral50: '#bdbdbd',
                colorNeutral60: '#9e9e9e',
                colorNeutral70: '#757575',
                colorNeutral80: '#373737',
                colorNeutral90: '#1b1b1b',
                colorInfo: '#1976d2',
                colorInfoContrast: '#fff',
                colorWarning: '#ffa000',
                colorWarningContrast: '#fff',
                colorAlert: '#a1001a',
                colorAlertContrast: '#fff',
                colorSuccess: '#43a047',
                colorSuccessContrast: '#fff',
                colorDisabled: '#ccc',
                colorDisabledContrast: '#1f1f1b',
                black: '#1f1f1b',
                white: '#fff',
                bgFooter: '#f2f2f2',
                bgHeader: 'white',
                bgPhoneIcon: '#a1001a',
                bgPopup: 'rgba(0,0,0,.85)',
                bgSelected: 'rgba(48,69,98,.1)',
                bgTile1: '#a1001a',
                bgTile2: '#2f2f2f',
                bigBoldLabel: '#595959',
                bgTextOverlay1: 'rgba(0,0,0,0.6)',
                colorIconHeader: '#000',
                desktopHeaderMenu: {
                    background: '#f4f2f7',
                    text: '#333',
                    border: '1px solid #f5f5f5',
                    maxWidth: '1400px',
                },
                disableButton: '#d8d8d8',
                error: {
                    main: '#ff0000',
                },
                gfBgColor: '#9872bb',
                gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
                gfMobileLabelTextColor: '#212121',
                gfMobileSelectOptionTextColor: '#606060',
                gfMobileZipInputLabelTextColor: '#a0a0a0',
                gfTabDesktopInputBorder: '1px solid #333',
                globalBannerBackgroundColor: 'black',
                globalBannerTextColor: 'white',
                globalBannerTextDecoration: 'none',
                linkMenu: '#595959',
                orderConfirmationHeader: '#595959',
                orderConfirmationText: 'black',
                promoGiftButtons: {
                    background: '#e8eaec',
                    color: '#2f2f2f',
                },
                globalBannerContainerBackground: '#ffffff',
                globalBannerContainerBorder: '#575757',
                globalBannerMainTextColor: '#2f2f2f',
                pdp: {
                    subscription: {
                        checkboxColor: '#3D70B2',
                        rootContainerBackground: '#efdfb0',
                        boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                        overlayContainerBackground: '#fff',
                        modalColor: '#fff',
                        subTable: '#f7eed7',
                        linkColor: '#304562',
                        goButton: '#a1001a',
                        goButtonColor: '#fff',
                    },
                },
                primary: {
                    500: '#595959',
                },
                primaryButton: '#a1001a',
                productTitleColor: '#2f2f2f',
                sectionHeaderTextColor: '#202020',
                secondaryButton: '#595959',
                shape: {
                    borderRadius: '4px',
                },
                tickCheck: '#00CC33',
                snipeText: '#3c3c35',
                snipeTextPromo: '#a1001a',
                snipeTextBackground: 'rgba(255,255,255,0.82)',
                snipeTextFontSize: '14px',
                snipeTextFontWeight: 500,
                uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
                unCheckRadioBtn: '#595959',
                checkRadioBtn: '#a1001a',
                copyright: '#747472',
                mobileMenuBG: {
                    header: '#909090',
                    main: '#fff',
                    product: '#fff',
                    alternate: '#909090',
                    nonProduct: '#909090',
                    parent: '#909090',
                    child: '#e8e8e8',
                    leftMenuBG: '#909090',
                },
                mobileFooterBottomLink: '#747472',
                desktopBgFooter: '#f2f2f2',
                modalBg: '#f2f2f2',
                modalTile: '#f2f2f2',
                cta1: '#2f2f2f',
                cta2: '#a94',
                cta3: '#3c3c35',
                cta4: '#595959',
                pink1: '#8E1429',
                brown1: '#5b322c',
                brown2: '#311714',
                brown3: '#600600',
                brown4: '#d9d0c1',
                brown5: 'rgba(255,255,255,0.82)',
                brown6: '#ded8d8',
                grey1: '#2f2f2f',
                grey2: '#3c3c35',
                grey3: '#5c5c52',
                grey4: '#7c7c70',
                grey5: '#909090',
                grey6: '#d1d1c1',
                grey7: '#e3e3e3',
                grey8: '#cccccc',
                grey9: '#e8eaec',
                grey10: '#595959',
                grey11: '#e4e4e4',
                flowerGift: {
                    topLogoText: {
                        color: '#000000',
                    },
                    titleBg: {
                        background: 'rgba(0, 0, 0, 0.1)',
                    },
                },
                sympathyPage: {
                    buttonColor: '#50396E',
                    stickyBackground: '#fff',
                    borderColors: '#ccc',
                    containerWidth: '1400px',
                },
                myAccount: {
                    contentGridBorder: '#7c7c7d',
                    blockBorderRight: '#e2e2e2',
                    blockMessageFadedInside: '#7c7c7d',
                    blockHeaderbackground: '#ededea',
                    blockActions: '#619ee6',
                    caretSign: '#000',
                    activeLink: '#000',
                    divider: '#ccc',
                    orderRow: '#f6f6f4',
                    container: {
                        backgroundColor: 'transparent',
                        maxWidth: {
                            downSmall: '100%',
                            downMedium: '100%',
                            upMedium: '970px',
                            upLarge: '1170px',
                        },
                    },
                },
                checkoutGuestForgotLinkColor: '#3b73af',
                checkout: {
                    payment: {
                        background: '#fff',
                    },
                },
                tiers: {
                    card: {
                        backgroundColor: '#fff',
                    },
                    image: {
                        maxWidth: '100%',
                        height: 'auto',
                    },
                    title: {
                        lineHeight: '42px',
                        color: '#fff',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        upSmall: {
                            textAlign: 'left',
                        },
                        downSmall: {
                            textAlign: 'center',
                        },
                    },
                    container: {
                        backgroundColor: 'transparent',
                        maxWidth: {
                            downSmall: '100%',
                            downMedium: '100%',
                            upMedium: '970px',
                            upLarge: '1170px',
                        },
                    },
                },
            },
            typography: {
                useNextVariants: true,
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '14px',
            },
            excludeSEO: [
                '/gifthistory-home-gift-baskets',
                '/About-Us-Privacy-Policy',
                '/h/view/about01',
            ],
            auth_client_id: 'M8wfPq68Wxh0NSf6Vq8MpVsd70LtXOzo',
            auth_passwordless_client_id: 'M8wfPq68Wxh0NSf6Vq8MpVsd70LtXOzo',
            'brand-class-id': '',
            'brand-id': 'HD',
            'bloomreach-auth-key': '3usrhaoo4j20bl1z',
            'bloomreach-account-id': 5027,
            'category-brand-id': 'HD',
            'display-name': 'Harry & David',
            'domain-name': 'harryanddavid',
            identifier: 'HarryAndDavid',
            'order-service-email': 'service@harryanddavid.com',
            'partnum-prefix': '1019-I-',
            phoneNumber: '1-877-322-1200',
            phoneNumberShort: '18773221200',
            'product-brand-id': 1019,
            'sales-catalog-id': 16802,
            'store-id': 21051,
            'calendar-cap-week-per-month': 1,
            favicon: 'https://cdn1.harryadndavid.top/favicon.ico',
            'passport-brand-id': 1001,
            'passport-item-id': 149712,
            'passport-item-renew-id': 149715,
            'passport-item-price': 29.99,
            'passport-item-renew-price': 19.99,
            'passport-item-duration-short-text': 'Year',
            'passport-item-duration-long-text': 'One Full Year',
            'qas-address-search-limit': 100,
            'shoprunner-partner-code': '1800HARRY',
            'apple-pay-merchant-id': 'merchant.com.1800flowers.1800flowersapplepaywebTEST',
            'ibmcloud-api-url': 'api.us-east.apiconnect.ibmcloud.com/1800flowers-cloud-apic-east-space/sb',
            'ibmcloud-client-id': '29b33fcb-0b08-4603-bf12-5026eaaa1677',
            'ibmcloud-secret': 'F3yG5rF0mX8bI5rP2fO0mJ0jK8oU4iG7tC5pX7gC8mC5yH2pK6',
            'ibmcloud-token': 'eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NTg0NjAzMTMsImlzcyI6InN1YnNjcmlwdGlvbl9hcGkifQ.SNqMulkz_uYiKT-vCC47CUAVKCnJomYuH2kIUE5mRnA',
            gift_card_bin_range: '6035710215260094|6035710215260096',
            gift_card_source_id: 'W0099',
            no_of_gift_card_can_apply: 2,
            'payment-division-number': 258886,
            'gift-card-division-number': 848481,
            'company-code': '205',
            'passport-ref-address.city': 'Medford',
            'passport-ref-address.state': 'OR',
            'passport-ref-address.zipcode': 97501,
            'product-image-path': '.harryanddavid.com/wcsstore/HarryAndDavid/images/catalog/',
            'product-image-cdn': 'cdn1',
            'snipe-image-folder': 'snipes/',
        },
    },
    page: {
        brands: {
            byId: {
                '18F': {
                    'brand-id': '18F',
                    code: '18F',
                },
                HD: {
                    code: 'HD',
                },
            },
        },
    },
};

export default initialState;
